import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import toast from '@/utils/toast';

export default function useFeeModal(props) {
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  // Table Handlers
  const columns = [

    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Tên dịch vụ'),
      field: 'name',
    },
    {
      label: t('Loại dịch vụ'),
      field: 'feeTypeObject.title',
    },
    {
      label: t('Cách tính dịch vụ'),
      field: 'feeCalculationTypeObject.title',
    },
    {
      label: t('Giá tiền'),
      field: 'price',
      type: 'number',
      width: '150px',
    },
  ];
  const rows = ref([]);
  // filter
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {
    },
    sort: {},
    page: 1,
    perPage: 100,
  });

  const contract = computed(() => props.contract);
  //   API Call
  const fetchFees = () => {
    serverParams.value = {
      searchTerm: '',
      filter: {
        apartmentId: props.apartment ? props.apartment.id : null,
      },
      sort: {},
      page: 1,
      perPage: 100,
    };
    store
      .dispatch('contract/fetchFees', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        const data = [...items];
        if (props.feesSelected && props.feesSelected.length) {
          const listIdFeesSelected = props.feesSelected.map(fee => fee.id);
          const dataRes = data.filter(res => !listIdFeesSelected.includes(res.id));
          rows.value = [...dataRes];
        } else rows.value = data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchFees();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const resetValue = () => {
    rows.value = [];
    // filter
    type.value = null;
    // current selected rows
    selectedRows.value = [];
    searchTerm.value = '';
    // ssr
    isLoading.value = false;
    totalRecords.value = 0;
    serverParams.value = {
      searchTerm: '',
      filter: {},
      sort: {},
      page: 1,
      perPage: 20,
    };
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });

  // ui
  const resolvePriceDisplay = val => {
    if (contract.value) {
      if (val.typeId === 'lease') {
        return Number(contract.value.price).toLocaleString();
      } if (val.typeId === 'deposit') {
        return Number(contract.value.deposit).toLocaleString();
      }
    }

    return Number(val.price).toLocaleString();
  };

  return {
    columns,
    rows,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchFees,
    t,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    resetValue,
    resolvePriceDisplay,
  };
}
